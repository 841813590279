/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W 2024 roku rozpoczęły się prace nad inwestycją budowy ścieżki rowerowej, której tor przebiega na odcinku Mostki-Kowal w województwie kujawsko-pomorskim, w powiecie włocławskim."), "\n", React.createElement(_components.p, null, "W ramach robót zostały wykonane między innymi następujące czynności:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "budowa ścieżki rowerowej odsuniętej od krawędzi jezdni, o nawierzchni bitumicznej,"), "\n", React.createElement(_components.li, null, "wypełnienie przestrzeni między krawędzią jezdni a ścieżką kruszywem kamiennym,"), "\n", React.createElement(_components.li, null, "wykonanie przejścia dla pieszych oraz przejazdów dla rowerów w ciągu ścieżki,"), "\n", React.createElement(_components.li, null, "przebudowa istniejących zjazdów,"), "\n", React.createElement(_components.li, null, "wykonanie odwodnienia powierzchniowego za pomocą spadków nawierzchni na przyległy teren."), "\n"), "\n", React.createElement(_components.p, null, "Termin realizacji przewidywany był na 6 miesięcy. Inwestycja planowo została oddana do użytku w lipcu 2024 roku."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
